import React from 'react';
import Script from 'next/script';

declare global {
  interface Window {
    google: any;
  }
}
function GoogleSignInScript() {
  return (
    <Script
      id="google-sign-in-script"
      src="https://accounts.google.com/gsi/client"
      async
      defer
    />
  );
}
export default GoogleSignInScript;
