export const errToString = (err: any) => {
    if (typeof err?.response?.data?.msg === 'string') {
      return err?.response?.data?.msg;
    }
    if (typeof err?.error?.message === 'string') {
      return err?.error?.message;
    }
    if (typeof err?.message === 'string') {
      return err?.message;
    }
    return (
      JSON.stringify(err?.response?.data?.msg)
      || err.toString()
      || JSON.stringify(err)
      || err
      || 'Something went wrong'
    );
  };
  