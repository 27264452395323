import StandardClaims from './StandardClaims';
import JwtToken from './JwtToken';

export interface GoogleTokenInterface extends StandardClaims {
  email: string;
  email_verified: boolean;
  name: string;
}

export default class GoogleToken extends JwtToken {
  payload!: GoogleTokenInterface;

  constructor(token: string) {
    super(token);
    this.payload = this.decodePayload();
  }

  decodePayload(): GoogleTokenInterface {
    const obj = super.decodePayload() as GoogleTokenInterface;
    return obj;
  }

  getEmail(): string {
    return this.payload?.email;
  }
}
