import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import styles from '../styles/Home.module.css';
import { errToString } from '../utils/errors';
import { ADMIN_STORAGE_KEY } from '../constants';
import Loading from '../components/Loading';
import GoogleSignInButton from '../components/GoogleSignInButton';
import GoogleToken from '../utils/jwt/GoogleToken';
import AccessTokenHandler from '../utils/jwt/accessTokenHandler';
import { API } from '../api';

export default function Home() {
  const router = useRouter();
  const [err, seterr] = useState<string | null>(null);
  const [loading, setloading] = useState(false);
  const [oauthJWT, setoauthJWT] = useState<GoogleToken | null>(null);

  const onSubmit = React.useCallback(async () => {
    if (!AccessTokenHandler) {
      seterr('You need to have access token handler');
      return;
    }
    if (!oauthJWT) {
      seterr('You need to sign in with Google');
      return;
    }
    setloading(true);
    seterr(null);
    try {
      const data = {
        token: oauthJWT?.token,
      };

      const api = API.getOrCreateInstance();
      const r = await api.admin().login(data);
      AccessTokenHandler.storeTokens(r?.data?.token);

      localStorage?.setItem(ADMIN_STORAGE_KEY, JSON.stringify(r?.data?.admin));
      router.push('/dashboard');
    } catch (e) {
      seterr(errToString(e));
    } finally {
      setloading(false);
    }
  }, [oauthJWT, AccessTokenHandler, router]);

  useEffect(() => {
    if (oauthJWT?.payload?.email) {
      onSubmit();
    }
  }, [oauthJWT]);

  return (
    <div className={styles.container}>
      <Head>
        <title>Typedream</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h3 className={styles.title}>
          Login
        </h3>
        {!loading && (

          <GoogleSignInButton
            setoauthJWT={setoauthJWT}
          />
        )}
        {err && <div className="has-text-danger mt-3">{err}</div>}
      </main>
      <Loading loading={loading} />
    </div>
  );
}
