import React from 'react';
import classNames from 'classnames';

function Loading({ loading }: {loading: boolean}) {
  return (
    <div
      className={classNames('loader-wrapper', {
        'is-active': loading,
      })}
    >
      <div className="loader-box">
        <div className="loader is-loading" />
      </div>
    </div>
  );
}

export default React.memo(Loading);
